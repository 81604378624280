import {ActionCatalogTypes} from '../reducers/transactionsCatalog';
// import {ActionTypes} from '../reducers/apiInfo';
import API from '../services/api';

export const requestStart = () => ({
	type: ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_START,
});

export const requestSuccess = list => {
	return {
		type: ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_SUCCESS,
		payload: list
	};
};

export const requestFailure = error => {
	return {
		type: ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_FAILURE,
		payload: error
	}
};

// export const requestGenerateSuccess = data => {
// 	return {
// 		type: ActionTypes.FETCH_API_INFO_GENERATED_SUCCESS,
// 		payload: data
// 	}
// };

// export const requestGenerateError = error => {
// 	return {
// 		type: ActionTypes.FETCH_API_INFO_GENERATED_ERROR,
// 		payload: error,
// 	}
// };

export const getTransactionsCatalogFetchList = data => dispatch => {
	dispatch(requestStart());
	return API.transactionsCatalog
		.apiCatalogList(data)
		.then(response => {
			dispatch(requestSuccess(response.data));
		})
		.catch(error => dispatch(requestFailure(error.toString())));
};

// export const transactionsCatalogGenerateFile = data => dispatch => {
// 	dispatch(requestStart());
// 	return API.apiCatalog
// 		.apiCatalogCreate(data)
// 		.then(response => {
// 			dispatch(requestGenerateSuccess(response.data));
// 		})
// 		.catch(error => dispatch(requestGenerateError(error)));
// };
