export const filterFolders = (data) => data.filter(o => {
    if (o.children) o.children = filterFolders(o.children);
    return o.type !== 1;
});

export const getArrayWithBreadcrumbs = (data) => {
    const result = [];

    const getBreadcrumbs = data => data.forEach(item => {
        if (!item.breadcrumbs) {
            item.breadcrumbs = [];
        };

        if (item.children) {
            item.children.forEach(i => {
                i.breadcrumbs = [...item.breadcrumbs, `${item.id}. ${item.name}`]
            });

            getBreadcrumbs(item.children);
        };

        result.push(item);
    })

    getBreadcrumbs(data);

    return result;
};

export const dateWithTimeZone = (timeOffset, time) => {
    let respDate = new Date(Date.parse(`${time}`)),
        utc = respDate.getTime() + respDate.getTimezoneOffset()*60000,
        newDate = new Date(utc + (timeOffset ? timeOffset : 0)*3600000 ).toLocaleString();
    return newDate
};

export const isJson = (str) => {
    if (!str) {return false}
    try {JSON.parse(str)} catch (e) {return false}
    return true;
};

export function getTotalSeconds(timeString) {
    const timeParts = timeString.split(' ');
    let totalSeconds = 0;
  
    for (let i = 0; i < timeParts.length; i++) {
        const part = timeParts[i];
        const value = parseInt(part.slice(0, -1));
        const unit = part.slice(-1);
  
        if (!isNaN(value)) {
            switch (unit) {
                case 'd':
                    totalSeconds += value * 24 * 60 * 60;
                    break;
                case 'h':
                    totalSeconds += value * 60 * 60;
                    break;
                case 'm':
                    totalSeconds += value * 60;
                    break;
                case 's':
                    totalSeconds += value;
                    break;
            }
        }
    }

    return totalSeconds;
};

export function convertSecondsToTimeString(totalSeconds) {
    const days = Math.floor(totalSeconds / (24 * 60 * 60));
    const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
    const seconds = totalSeconds % 60;
  
    const timeString = `${days ? `${days}d ` : ''}${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
    return timeString;
};

export const convertTimeToSeconds = (str) => {
    let seconds = 0;
    const arrTime = str.split(':')
    seconds = (arrTime[0] * 60 * 60) + (arrTime[1] * 60)
    return seconds
}

export const convertSecondsToTime = (int) => {
    let timeString = ''; 
    let h = Math.floor(int / 3600);
    let m = Math.floor((int % 3600) / 60);
    // eslint-disable-next-line
    const newLocal = timeString = `${h < 10 ? '0' + h : h}:${m < 10 ? '0' + m : m}`;
    return timeString
}

export const createRestartableTimer = (callback, delay) => {
	let timerId = null;
	function start() {stop(); timerId = setTimeout(callback, delay);}
	function stop() {if (timerId !== null) { clearTimeout(timerId); timerId = null;}}
	function restart(newDelay = delay) {delay = newDelay; start();}
	return {start,stop,restart};
}