import {combineReducers} from 'redux';
import {reducer as formReducer} from 'redux-form';
import {timeZone} from './timezone';
import {authorization} from './authorization';
import {historyTransStatus} from './historyTransStatus';
import {queue} from './queue';
import {buildApi} from './buildApi';
import {repeatQueue} from './repeatQueue';
import {logViewer} from './logViewer';
import {migration} from './migration';
import {nodeManagement} from './nodeManagement';
import {statements} from './statements';
import {systemInfoBuierLog} from './systemInfoBuierLog';
import {systemInfoTempDepositLogs} from './systemInfoTemporiaryDepositLogs';
import {wallets} from './wallets';
import {systemInfoAddresses} from './systemInfoAddresses';
import {hdbkRequestType} from './hdbkRequestType';
import {userPaymentSystems} from './userPaymentSystems';
import {telegram} from './telegram';
import {notificationSettings} from './notificationSettings';
import {blockchainConfig} from './blockchainConfig';
import {settingsPPS} from './settingsPPS';
import {requestCleaner} from './requestCleaner';
import {requestElasticSearch} from './requestElasticSearch';
import {merchants} from './merchants';
import {transactions} from './transactions';
import {actionsLogs} from './usersActionLog';
import {visitLogs} from './usersVisitLog';
import {usersAll} from './usersAll';
import {paymentSystems} from './paymentSystems';
import {currentUser} from './currentUser';
import {mappingCsacades} from './mappingCascades';
import {usersOnTheNode} from './usersOnTheNode';
import {dashboard} from './dashboard';
import {ipAddress} from './ipAddress';
import {permissionGroups} from './permissionGroups';
import {permissions} from './permission';
import {roles} from './roles';
import {mappingMultiaccounts} from './mappingMultiaccounts';
import {report} from './report';
import {currencyList} from './currencyList';
import {usersOnTheBranch} from './usersOnTheBranch';
import {apiInfo} from './apiInfo';
import {apiCatalog} from './apiCatalog';
import {apiTransactionsCatalog} from './transactionsCatalog';

export const rootReducer = combineReducers({
    timeZone,
    authorization,
    historyTransStatus,
    queue,
    buildApi,
    repeatQueue,
    logViewer,
    migration,
    nodeManagement,
    statements,
    systemInfoBuierLog,
    systemInfoTempDepositLogs,
    wallets,
    systemInfoAddresses,
    hdbkRequestType,
    userPaymentSystems,
    telegram,
    notificationSettings,
    blockchainConfig,
    settingsPPS,
    requestCleaner,
    requestElasticSearch,
    merchants,
    transactions,
    actionsLogs,
    visitLogs,
    usersAll,
    paymentSystems,
    currentUser,
    mappingCsacades,
    usersOnTheNode,
    dashboard,
    ipAddress,
    permissionGroups,
    permissions,
    roles,
    mappingMultiaccounts,
    report,
    currencyList,
    usersOnTheBranch,
    apiInfo,
    apiCatalog,
    apiTransactionsCatalog,
    form: formReducer,
});