export namespace ActionCatalogTypes {
    export const FETCH_API_TRANSACTIONS_CATALOG_START = 'FETCH_API_TRANSACTIONS_CATALOG_START';
    export const FETCH_API_TRANSACTIONS_CATALOG_SUCCESS = 'FETCH_API_TRANSACTIONS_CATALOG_SUCCESS';
    export const FETCH_API_TRANSACTIONS_CATALOG_FAILURE = 'FETCH_API_TRANSACTIONS_CATALOG_FAILURE';
};

export type APICatalogItem = any;

type APICatalogList = any;

interface APICatalog {
	isLoading: boolean,
	apiCatalogList: APICatalogList,
	errorMessage: any,
	isError: boolean,
}

export const initialState: APICatalog = {
	isLoading: false,
	isError: false,
	apiCatalogList: {
		'hydra:totalItems': 0,
		'hydra:member': []
	},
	errorMessage: '',
}

export const apiTransactionsCatalog = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_START:
			return {
				...state,
				isLoading: true
			};
		case ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_SUCCESS:
			return {
				...state,
				apiCatalogList: payload,
				isLoading: false,
			};
		case ActionCatalogTypes.FETCH_API_TRANSACTIONS_CATALOG_FAILURE:
			return {
				...state,
				isLoading: false,
				isError: true,
				errorMessage: payload
			};
		default:
			return state;
	}
};
