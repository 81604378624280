import React, {Component} from "react";
import { styled } from "@mui/material/styles";
import { RootState } from '../../store/configure-store';
import { compose } from "redux";
import { connect } from 'react-redux';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Loader } from '../../components/loaders/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TablePagination from '@mui/material/TablePagination';
import {
    apiInfoFetchList,
    apiInfoFetchCurrencyList,
    apiInfoFetchPMMethodsList,
} from '../../actions/apiInfo';
import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
import {catalogGenerateFile} from '../../actions/apiCatalog';
import {FilterListSvg} from "../../components/svg/filter-list-svg";
import { TableFilter } from "../../components/table-filter";
import Menu from '@mui/material/Menu';
import SvgIcon from '@mui/material/SvgIcon';
import {Button} from '../../components/button';
import Snackbar from '@mui/material/Snackbar';
import { InfoPopup } from "../../components/info-popup";



const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',

    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

const DepositMainSecondRowLeftButtons = styled('div')({
    '@media(max-width: 768px)' : {
        '& button': {
            boxSizing: 'border-box',
            fontSize: '12px',
            height: '30px',
            padding: '0 12px',
            marginRight: '11px',
            '&:last-child':{
                marginRight: '0px',
            }
        }
    }
});

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    position: sticky;
    z-index: 10;
    top: 0;
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 260px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
    '&.activeFilterClass':{
        background: '#38AA72',
        '& div':{
            color: '#fff',
            '& svg path':{
                fill: '#fff',
            }
        }
    }
}));

const TableCellContent = styled('div')(`
    display: flex;
    align-items: center;
`);

const IconWrapper = styled('div')(`
    margin-left: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
`);

const StyledMenu = styled(Menu)(({ theme }) => (`
    .MuiPaper-root{
        border-radius: 0;
        & > .MuiList-root{            
            padding: 0 0 8px 0;
        }
    }
`));

const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
    position: relative;
    // padding-right: 30px;
    min-width: 120px;
    widt: 25%;
    box-sizing: border-box;
    justify-content: flex-start;
`));

const SortButtons = styled('div')(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px;
`));

const SortButtonsSongleToLeft = styled(SortButtons)(({ theme }) => (`
    position: absolute;
    right: 0;
    top: -10px
`));

const SortButtonTop = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        bottom: -7px;
        position: relative;
        width: 12px;
        height: 12px;
    }
`));

const SortButtonBottom = styled('div')(({ theme }) => (`
    & svg{ 
        cursor: pointer;
        position: relative;
        top: -5px;
        width: 12px;
        height: 12px;
    }
`));

const StatusInfoContainer = styled('div')({
    'div': {
        '&:last-child': {
            marginBottom: '0px'
        }
    }
});

// const StatusInfoItem = styled('div')({
//     marginBottom: '18px'
// });


type PropsType = {
    isLoading: boolean, 
    isErrorGenerate: boolean,
    APILIst: Array<any>,
    listCurrency: Array<string>,
    litPSMethod: Array<string>,
    totalItems: number,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: number,
    paymentSystemsList: Array<any>,
    generatedFileID: number,
    apiInfoFetchList: (data: any) => void,
    apiInfoFetchCurrencyList: () => void,
    apiInfoFetchPMMethodsList: () => void,
    getPaymentSystemsListRequest: () => void,
    catalogGenerateFile: (data: any) => void,
}

type StateProps = {
    page: number,
    // rowsPerPage: 100,
    anchorElWay: null | any,
    way: Array<string>,
    anchorElCurrency: null | any,
    currency: Array<string>,
    anchorElPaymentMethod: null | any,
    paymentMethod: Array<string>,
    anchorElPaymentSystems: null | any,
    paymentSystems: Array<string>,
    oeder: string,
    itemsPerPage: number,
    openSnakebarGenerate: boolean,
}

const filterItemsWay = [
    {title: 'Deposit', value: 'deposit'}, 
    {title: 'Withdraw', value: 'withdraw'}, 
];


export class ApiInfo extends Component<PropsType, StateProps> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            // rowsPerPage: 100,
            anchorElWay: null,
            way: [],
            anchorElCurrency: null,
            currency: [],
            anchorElPaymentMethod: null,
            paymentMethod: [],
            anchorElPaymentSystems: null,
            paymentSystems: [],
            oeder: 'desc',
            itemsPerPage: 50,
            openSnakebarGenerate: false,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({page: 0, itemsPerPage: parseInt(event.target.value)}, () => {this.updateData()})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleWayMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElWay: e.currentTarget});
    }
    handleWayMenuClose = () => {
        this.setState({anchorElWay: null});
    }
    onChangeWayFilter = (data) => {
        this.setState({way: data}, () => {this.updateData()});
        this.handleWayMenuClose();
    }

    handleCurrencyMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElCurrency: e.currentTarget});
    }
    handleCurrencyMenuClose = () => {
        this.setState({anchorElCurrency: null});
    }
    onChangeCurrencyFilter = (data) => {
        this.setState({currency: data}, () => {this.updateData()});
        this.handleCurrencyMenuClose();
    }

    handlePaymentMethodMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentMethod: e.currentTarget});
    }
    handlePaymentMethodMenuClose = () => {
        this.setState({anchorElPaymentMethod: null});
    }
    onChangePaymentMethodFilter = (data) => {
        this.setState({paymentMethod: data}, () => {this.updateData()});
        this.handlePaymentMethodMenuClose();
    }

    handlePaymentSystemsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentSystems: e.currentTarget});
    }
    handlePaymentSystemsMenuClose = () => {
        this.setState({anchorElPaymentSystems: null});
    }
    onChangePaymentSystemsFilter = (data) => {
        this.setState({paymentSystems: data}, () => {this.updateData()});
        this.handlePaymentSystemsMenuClose();
    }

    handleClickSortTopUpdate = () => {
        this.setState({oeder: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({oeder: 'desc'}, () => this.updateData())
    };

    handleClearFilters = () => {
        this.setState({
                page: 0,
                anchorElWay: null,
                way: [],
                anchorElCurrency: null,
                currency: [],
                anchorElPaymentMethod: null,
                paymentMethod: [],
                anchorElPaymentSystems: null,
                paymentSystems: [],
                oeder: 'desc',
            }, 
            () => {this.updateData()}
        )
    }
    
    componentDidMount() {
        const {
            routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(
            routsUser.some(rout => rout.child.includes('/api-info/catalog')) || 
            routsUser.some(rout => rout.child.includes('/api-info/index')) || 
            userRoles.some(role => role.name === 'Admin') || 
            !!isSuperadmin
        ){
            const {
                apiInfoFetchCurrencyList,
                apiInfoFetchPMMethodsList,
                getPaymentSystemsListRequest,
            } = this.props;

            this.updateData();
            apiInfoFetchCurrencyList();
            apiInfoFetchPMMethodsList();
            getPaymentSystemsListRequest();
        }
    }

    updateData = () => {
        const {
            paymentSystems,
            page,
            way,
            currency,
            paymentMethod,
            oeder,
            itemsPerPage,
        } = this.state;

        const {
            apiInfoFetchList,
        } = this.props;

        const data = {
            'paymentSystem.id[]': paymentSystems,
            'page': page + 1,
            'way[]': way,
            'currency[]': currency,
            'paymentMethod[]': paymentMethod,
            'order[dateLastTransaction]': oeder,
            'itemsPerPage': itemsPerPage,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        apiInfoFetchList(data);
    }

    handleCloseSnakebarGenerate = () => {
        this.setState({openSnakebarGenerate: false});
    };
    handleOpenSnakebarGenerate = () => {
        this.setState({openSnakebarGenerate: true});
    };

    // Data for Download/Create file
    handleCreateFile = () => {
        const {catalogGenerateFile} = this.props;
        const {
            paymentSystems,
            way,
            currency,
            paymentMethod,
        } = this.state;

        const data = {
            'paymentSystem.id': paymentSystems,
            'way': way,
            'currency': currency,
            'paymentMethod': paymentMethod,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
            if (data[key].length === 0) delete data[key];
        });

        const body = {
            params: JSON.stringify({...data})
        }

        // @ts-ignore
        catalogGenerateFile(body).then(() => {
            this.handleOpenSnakebarGenerate();
        })
    }

    isClearedFilters = () => {
        const {                
            page,
            way,
            currency,
            paymentMethod,
            paymentSystems,
        } = this.state
        return !(page > 0 || way.length || currency.length || paymentMethod.length || paymentSystems.length)
    }

    render() {
        const {
            isLoading,
            APILIst,
            routsUser, 
            userRoles, 
            isSuperadmin,
            totalItems,
            listCurrency,
            litPSMethod,
            paymentSystemsList,
            isErrorGenerate,
            generatedFileID,
        } = this.props

        const {
            page,
            // rowsPerPage,
            itemsPerPage,
            way,
            anchorElWay,
            currency,
            anchorElCurrency,
            paymentMethod,
            anchorElPaymentMethod,
            paymentSystems,
            anchorElPaymentSystems,
            openSnakebarGenerate,
            //...
        } = this.state;

        const accessedRole = routsUser.some(rout => rout.child.includes('/api-info/catalog')) || routsUser.some(rout => rout.child.includes('/api-info/index')) || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        const isWayFilterOpen = Boolean(anchorElWay);
        const isCurrencyFilterOpen = Boolean(anchorElCurrency);
        const isanchorElPaymentMethodFilterOpen = Boolean(anchorElPaymentMethod);
        const isanchorElPaymentSystemsFilterOpen = Boolean(anchorElPaymentSystems);        

        // console.log('this.state', this.state)

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Integrations Catalog</DepositMainHeaderText>
                            </DepositMainHeader>
                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    <DepositMainSecondRowLeftButtons>
                                        <Button
                                            onClick={this.handleClearFilters}
                                            variant="outlined"
                                            disabled={this.isClearedFilters()}
                                            sx={{marginRight: '16px'}}
                                        >
                                            Clear Filters
                                        </Button>
                                        {routsUser.some(rout => rout.child.includes('/api-info/catalog') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin) && 
                                            <Button
                                                onClick={this.handleCreateFile}
                                                variant="outlined"
                                                disabled={this.isClearedFilters()}
                                                // disabled={true}
                                            >
                                                Generate file
                                            </Button>
                                        }
                                    </DepositMainSecondRowLeftButtons>
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>
                                            <StyledTableHead>
                                                <TableRow>

                                                    <TableCellWithActiveClass 
                                                        style={{minWidth: '200px'}}
                                                        width='300px' 
                                                        align="right"
                                                        className={ paymentSystems.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            Payment System
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handlePaymentSystemsMenuOpen}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElPaymentSystems}
                                                                open={isanchorElPaymentSystemsFilterOpen}
                                                                onClose={this.handlePaymentSystemsMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={paymentSystemsList.map(item => {
                                                                        return {
                                                                            title: item.name, 
                                                                            value: item.id, 
                                                                        }
                                                                    })}
                                                                    initialData={paymentSystems}
                                                                    onApplyFilter={this.onChangePaymentSystemsFilter}
                                                                    onClearAll={this.onChangePaymentSystemsFilter}
                                                                    withSearch
                                                                    label='Payment systems'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>

                                                    <TableCellWithActiveClass 
                                                        width='200px' 
                                                        align="right"
                                                        className={ way.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            Way
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleWayMenuOpen}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElWay}
                                                                open={isWayFilterOpen}
                                                                onClose={this.handleWayMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={filterItemsWay}
                                                                    initialData={way}
                                                                    onApplyFilter={this.onChangeWayFilter}
                                                                    onClearAll={this.onChangeWayFilter}
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>

                                                    <TableCellWithActiveClass 
                                                        width='200px' 
                                                        align="right"
                                                        className={ currency.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            Currency
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handleCurrencyMenuOpen}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElCurrency}
                                                                open={isCurrencyFilterOpen}
                                                                onClose={this.handleCurrencyMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={listCurrency.map(item => {
                                                                        return {
                                                                            title: item, 
                                                                            value: item, 
                                                                        }
                                                                    })}
                                                                    initialData={currency}
                                                                    onApplyFilter={this.onChangeCurrencyFilter}
                                                                    onClearAll={this.onChangeCurrencyFilter}
                                                                    withSearch
                                                                    label='Currency'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>

                                                    <TableCellWithActiveClass 
                                                        style={{minWidth: '300px'}}
                                                        width='300px' 
                                                        align="right"
                                                        className={ paymentMethod.length > 0 ? 'activeFilterClass' : ''}
                                                    >
                                                        <TableCellContent>
                                                            Payment method
                                                            <IconWrapper>
                                                                <FilterListSvg size="16px" onClick={this.handlePaymentMethodMenuOpen}/>
                                                            </IconWrapper>
                                                            <StyledMenu
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorElPaymentMethod}
                                                                open={isanchorElPaymentMethodFilterOpen}
                                                                onClose={this.handlePaymentMethodMenuClose}
                                                                PaperProps={{
                                                                    style: {
                                                                        width: '210px',
                                                                    },
                                                                }}
                                                            >
                                                                <TableFilter
                                                                    filterItems={litPSMethod.map(item => {
                                                                        return {
                                                                            title: item, 
                                                                            value: item, 
                                                                        }
                                                                    })}
                                                                    initialData={paymentMethod}
                                                                    onApplyFilter={this.onChangePaymentMethodFilter}
                                                                    onClearAll={this.onChangePaymentMethodFilter}
                                                                    withSearch
                                                                    label='Payment method'
                                                                />
                                                            </StyledMenu>
                                                        </TableCellContent>
                                                    </TableCellWithActiveClass>

                                                    <TableCell
                                                        style={{minWidth: '200px'}}
                                                    >
                                                        <WithSortedTableCellContent>
                                                            Date Last Transaction
                                                            <SortButtonsSongleToLeft>
                                                                <SortButtonTop onClick={this.handleClickSortTopUpdate}>
                                                                    <SvgIcon viewBox="0 0 9 2">
                                                                        <path d="M0.666504 4.66602L4.6665 0.666016L8.6665 4.66602H0.666504Z" fill="#979799"/>
                                                                    </SvgIcon>
                                                                </SortButtonTop>
                                                                <SortButtonBottom onClick={this.handleClickSortBottomUpdate}>
                                                                    <SvgIcon viewBox="0 0 9 9">
                                                                        <path d="M0.666504 0.666016L4.6665 4.66602L8.6665 0.666016H0.666504Z" fill="#979799"/>
                                                                    </SvgIcon>
                                                                </SortButtonBottom>
                                                            </SortButtonsSongleToLeft>
                                                        </WithSortedTableCellContent>
                                                    </TableCell>
                
                                                </TableRow>
                                            </StyledTableHead>
                                            <TableBody>
                                                {(APILIst || []).map(itemAPI => {
                                                    return (
                                                        <TableRow key={Math.random()}>
                                                            <TableCell>
                                                                {itemAPI.paymentSystem?.name}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.way}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.currency}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.paymentMethod}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.dateLastTransaction}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>     

                                <TablePagination
                                    // rowsPerPageOptions={[itemsPerPage]}
                                    rowsPerPageOptions={[20, 50, 75, 100]}
                                    component="div"
                                    count={totalItems}
                                    rowsPerPage={itemsPerPage}
                                    page={page}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>

                            <Snackbar 
                                open={openSnakebarGenerate} 
                                autoHideDuration={6000} 
                                onClose={this.handleCloseSnakebarGenerate} 
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                            >
                                {!isErrorGenerate ? 
                                    <InfoPopup
                                        dataTestid='snakebarSuccessGenerateFile'
                                        severity="info"
                                        headerText="Generate file Success"
                                    >
                                        <StatusInfoContainer>
                                            {`ID Your file: ${generatedFileID}`}
                                        </StatusInfoContainer>
                                    </InfoPopup> :
                                    <InfoPopup
                                        dataTestid='snakebarErrorGenerateFile'
                                        severity="error"
                                        headerText="Server error"
                                        infoText={"Something went wrong. Please try again"}
                                    />
                                }
                            </Snackbar>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.apiInfo.isLoading, 
        APILIst: state.apiInfo.apiInfoList['hydra:member'],
        listCurrency: state.apiInfo.listCurrency,
        litPSMethod: state.apiInfo.litPSMethod,
        totalItems: state.apiInfo.apiInfoList['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
        paymentSystemsList: state.paymentSystems.paymentSystemsList['hydra:member'],
        isErrorGenerate: state.apiInfo.isErrorGenerate,
        generatedFileID: state.apiInfo.generatedFile.id,
    }), {
        apiInfoFetchList,
        apiInfoFetchCurrencyList,
        apiInfoFetchPMMethodsList,
        getPaymentSystemsListRequest,
        catalogGenerateFile,
    }),
)(ApiInfo)